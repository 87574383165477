import React from 'react';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import fetcher from '../libs/fetcher';
import LoadingSpinner from '../components/LoadingSpinner';

function Privacy() {
  const url = 'https://backend.bvdk.de/items/legals/2?fields=text';
  const [data, setData] = useState(null);

  useEffect(() => {
    async function getData() {
      setData(await fetcher(url));
    }

    getData();

    return;
  }, []);

  return (
    <div className='bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 m-auto'>
      <div className='md:grid md:grid-cols-3 md:gap-6'>
        <div className='md:col-span-1'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>
            Datenschutz
          </h3>
        </div>
        <div className='mt-5 md:col-span-2 md:mt-0 text-gray-900'>
          <div className='grid grid-cols-6 gap-6'>
            <div className='col-span-6'>
              {!data && <div className='flex justify-center h-64 items-center'><LoadingSpinner /></div>}
              <ReactMarkdown>{data?.data?.text}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
