export default function calc(data) {
  const kmCosts = data?.kmFull
    ? +data?.km * 0.3
    : +data?.km * 0.2 > 130
    ? 130
    : +data?.km * 0.2;

  let halfDays = 0; // Tagegeld Abwesenheit >= 8
  let fullDays = 0; // Tagegeld Abwesenheit >= 24

  //console.log('KM Geld: ', kmCosts);

  const travelStartDate = new Date(data?.startDate + 'T' + data?.startTime);
  const travelEndDate = new Date(data?.endDate + 'T' + data?.endTime);
  const daysOff =
    new Date(data?.endDate).getTime() - new Date(data?.startDate).getTime();

  if (daysOff === 0) {
    // Ein Tag Abwesenheit
    let timeDiff = Math.floor(
      ((travelEndDate.getTime() - travelStartDate.getTime()) /
        (1000 * 60 * 60)) %
        24
    );
    timeDiff >= 8 && halfDays++;

    
  } else if (daysOff === 86400000) {
    // Zwei Tage Abwesenheit
    let dateStartTomorow = new Date(data?.startDate + 'T00:00:00');
    dateStartTomorow.setDate(dateStartTomorow.getDate() + 1);
    let offFirstDay = Math.floor(
      ((dateStartTomorow.getTime() - travelStartDate.getTime()) /
        (1000 * 60 * 60)) %
        24
    ); //Abwesenheitsstunden erster Tag
    let offSecondDay = Math.floor(
      ((travelEndDate.getTime() - dateStartTomorow.getTime()) /
        (1000 * 60 * 60)) %
        24
    ); // Abwesenheitsstunden zweiter Tag
    offFirstDay >= 8 && halfDays++;
    offSecondDay >= 8 && halfDays++;
    console.log('Zwei Tage Abwesenheit h Tag 1: ', offFirstDay);
    console.log('Zwei Tage Abwesenheit h Tag 2: ', offSecondDay);
  } else if (daysOff > 86400000) {
    //Volle Tage
    let dateStartTomorow = new Date(data?.startDate + 'T00:00:00');
    dateStartTomorow.setDate(dateStartTomorow.getDate() + 1);
    let dateEndLastDay = new Date(data?.endDate + 'T24:00:00');
    dateEndLastDay.setDate(dateEndLastDay.getDate() - 1);
    fullDays = parseInt(
      (dateEndLastDay.getTime() - dateStartTomorow.getTime()) / 86400000
    );

    let offFirstDay = Math.floor(
      ((dateStartTomorow.getTime() - travelStartDate.getTime()) /
        (1000 * 60 * 60)) %
        24
    ); //Abwesenheitsstunden erster Tag
    let offSecondDay = Math.floor(
      ((travelEndDate.getTime() - dateEndLastDay.getTime()) /
        (1000 * 60 * 60)) %
        24
    ); // Abwesenheitsstunden letzter Tag
    offFirstDay >= 8 && halfDays++;
    offSecondDay >= 8 && halfDays++;
  }
  const perDayRate = data?.abroad ? +data?.country.split('/')[1] : 28;
  const perDayRateCountry = data?.abroad
    ? `(${data?.country.split('/')[0]})`
    : '';
  const rations = data?.allowance
    ? 0
    : data?.breakfast * perDayRate * 0.2 +
      data?.lunch * perDayRate * 0.4 +
      data?.dinner * perDayRate * 0.4;
  let dailyAllowance = data?.abroad
    ? !data?.country.split('/')[1]
      ? 0
      : +data?.country.split('/')[1]
    : 28; //Tagegelsatz

  dailyAllowance = data?.allowance
    ? 0
    : dailyAllowance * fullDays +
      (dailyAllowance / 2) * halfDays -
      data?.breakfast * dailyAllowance * 0.2 -
      data?.lunch * dailyAllowance * 0.4 -
      data?.dinner * dailyAllowance * 0.4 -
      data?.holidayDays * dailyAllowance;

  if (dailyAllowance < 0) dailyAllowance = 0;

  const etc =
    data?.etc?.reduce((acc, obj) => {
      return acc + +obj.price;
    }, 0) || 0;

  

  const total = kmCosts + +data?.oeffis + +data?.hotel + +dailyAllowance + etc;

  
  return {
    total: total.toFixed(2),
    etc: etc.toFixed(2),
    dailyAllowance: dailyAllowance.toFixed(2),
    kmCosts: kmCosts.toFixed(2),
    halfDays: halfDays,
    fullDays: fullDays,
    perDayRate: perDayRate,
    perDayRateCountry: perDayRateCountry,
    rations: rations.toFixed(2),
  };
}
