async function fetcher(url) {
  try {
    const req = await fetch(url);
    const res = await req.json();
    //console.log(res);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export default fetcher;
