import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Imprint from './pages/Imprint';
import Privacy from './pages/Privacy';
import Footer from './components/Footer';
import Settings from './pages/Settings';
import Header from './components/Header';
import { ServiceWorkerUpdateListener } from './ServiceWorkerUpdateListener';
import UpdateWaiting from './components/UpdateWaiting';

function App() {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState({});

  const handleUpdate = () => {
    swListener.skipWaiting(registration.waiting);
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && navigator.serviceWorker) { 
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);
      listener.onupdateinstalling = (installingEvent) => {
        console.log('SW installed', installingEvent);
      };
      listener.onupdatewaiting = (waitingEvent) => {
        console.log('new update waiting', waitingEvent);
        setUpdateWaiting(true);
      };
      listener.onupdateready = (event) => {
        console.log('updateready event');
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        listener.addRegistration(reg);
        setRegistration(reg);
      });
      return () => listener.removeEventListener();
    }
  }, []);

  return (
    <div className='py-5 overflow-auto break-words w-11/12 lg:w-10/12 xl:w-8/12 m-auto'>
      <UpdateWaiting
        updateWaiting={updateWaiting}
        handleUpdate={handleUpdate}
      />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/impressum' element={<Imprint />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/datenschutz' element={<Privacy />} />
      </Routes>
      <div className='pt-6 pb-3'>
        <Footer />
      </div>
    </div>
  );
}

export default App;
