export const personFields = {
  firstname: '',
  lastname: '',
  iban: '',
  street: '',
  plz: '',
  city: '',
  function: '',
  email: '',
};

export const travelFields = {
  reason: '',
  start: '',
  destination: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  startDateEvent: '',
  startTimeEvent: '',
  endDateEvent: '',
  endTimeEvent: '',
  holiday: false,
  holidayDays: '',
  km: '',
  oeffis: '',
  kmFull: false,
  kmFullNote: '',
  hotel: '',
  breakfast: '',
  lunch: '',
  dinner: '',
  abroad: '',
  allowance: false,
  country: '',
  etc: [{ name: '', price: '' }],
  note: '',
};

export const countrieList = [
  { name: 'Afghanistan', money: 25 },
  { name: 'Albanien', money: 22 },
  { name: 'Armenien', money: 20 },
  { name: 'Bosnien und Herzegowina', money: 19 },
  { name: 'Bulgarien', money: 18 },
  { name: 'China, Peking', money: 25 },
  { name: 'Ecuador', money: 22 },
  { name: 'Estland', money: 24 },
  { name: 'Guinea-Bissau', money: 26 },
  { name: 'Indien, Chennai', money: 26 },
  { name: 'Indien, im Übrigen', money: 26 },
  { name: 'Iran', money: 27 },
  { name: 'Jemen', money: 20 },
  { name: 'Kap Verde', money: 25 },
  { name: 'Kirgisistan', money: 22 },
  { name: 'Korea, Demokratische Volksrepublik', money: 23 },
  { name: 'Kosovo', money: 20 },
  { name: 'Laos', money: 27 },
  { name: 'Lesotho', money: 23 },
  { name: 'Litauen', money: 21 },
  { name: 'Moldau, Republik', money: 21 },
  { name: 'Mongolei', money: 22 },
  { name: 'Montenegro', money: 26 },
  { name: 'Namibia', money: 25 },
  { name: 'Nordmazedonien', money: 22 },
  { name: 'Pakistan, Islamabad', money: 19 },
  { name: 'Philippinen', money: 27 },
  { name: 'Polen, Breslau', money: 27 },
  { name: 'Polen, Danzig', money: 25 },
  { name: 'Polen, Krakau', money: 22 },
  { name: 'Polen, Warschau', money: 24 },
  { name: 'Polen, im Übrigen', money: 24 },
  { name: 'Portugal', money: 26 },
  { name: 'Rumänien, Bukarest', money: 26 },
  { name: 'Rumänien, im Übrigen', money: 22 },
  { name: 'Russische Föderation, Jekaterinburg', money: 23 },
  { name: 'Russische Föderation, Moskau', money: 25 },
  { name: 'Russische Föderation, St. Petersburg', money: 21 },
  { name: 'Russische Föderation, im Übrigen', money: 20 },
  { name: 'Serbien', money: 22 },
  { name: 'Slowakische Republik', money: 27 },
  { name: 'Sudan', money: 27 },
  { name: 'Südafrika, Kapstadt', money: 27 },
  { name: 'Südafrika, im Übrigen (außer Johannesburg)', money: 24 },
  { name: 'Tadschikistan', money: 22 },
  { name: 'Tschechische Republik', money: 26 },
  { name: 'Türkei, Istanbul', money: 21 },
  { name: 'Türkei, Izmir', money: 24 },
  { name: 'Türkei, im Übrigen', money: 14 },
  { name: 'Turkmenistan', money: 27 },
  { name: 'Ukraine', money: 21 },
  { name: 'Ungarn', money: 26 },
  { name: 'Weißrussland', money: 16 }
  
];