import React from 'react';

function Button({ bg, fontColor, label, click }) {
  return (
    <button
      onClick={click}
      className={`${bg} ${fontColor} hover:scale-110 rounded-lg px-4 py-2 font-light min-w-[6rem]`}
    >
      {label}
    </button>
  );
}

export default Button;

Button.defaultProps = {
  bg: 'bg-blue-950',
  fontColor: 'text-white',
  label: 'Button',
  click: (e) => {
    e.preventDefault();
    alert('Button click');
  },
};
