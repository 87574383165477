import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CircleStackIcon } from '@heroicons/react/24/outline';
import { readWholeStore, writeToStore } from '../libs/store';

export default function SaveModal({
  myState,
  setMyState,
  dataPerson,
  dataTravel,
}) {
  const [storePerson, setStorePerson] = useState(null);
  const [storeTravel, setStoreTravel] = useState(null);
  const [person, setPerson] = useState(null);
  const [travel, setTravel] = useState(null);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setPerson(null);
    setTravel(null);

    setStorePerson(readWholeStore('user'));
    setStoreTravel(readWholeStore('travel'));
    return;
  }, [myState]);

  return (
    <Transition.Root show={myState} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={setMyState}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <CircleStackIcon
                      className='h-6 w-6 text-gray-600'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Personendaten speichern
                    </Dialog.Title>
                    <p className='text-gray-500 text-sm'>
                      Dateinamen eingeben, oder bestehenden auswählen zum
                      überschreiben der Daten
                    </p>
                  </div>
                </div>
                <div className='w-full px-14 py-2'>
                  <input
                    onSelect={(e) => setPerson(e.target.value)}
                    onChange={(e) => setPerson(e.target.value)}
                    className='px-4 text-base h-10 text-gray-500 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                    list='user'
                  />
                  <datalist id='user' className='w-full'>
                    {storePerson?.map((item, i) => (
                      <option
                        className='w-full text-black'
                        key={i}
                        value={item.name}
                      />
                    ))}
                  </datalist>
                </div>

                <div className='sm:flex sm:items-start'>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-14 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      Reisedaten speichern
                    </Dialog.Title>
                  </div>
                </div>
                <div className='w-full px-14 py-2'>
                  <input
                    onSelect={(e) => setTravel(e.target.value)}
                    onChange={(e) => setTravel(e.target.value)}
                    className='px-4 text-base h-10 text-gray-500 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'
                    list='travel'
                  />
                  <datalist id='travel' className='w-full'>
                    {storeTravel?.map((item, i) => (
                      <option
                        className='w-full text-black'
                        key={i}
                        value={item.name}
                      />
                    ))}
                  </datalist>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    disabled={!person && !travel ? true : false}
                    type='button'
                    className={` disabled:bg-gray-300 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-950 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-950/80 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={() => {
                      if (person) writeToStore('user', dataPerson, person);
                      if (travel) writeToStore('travel', dataTravel, travel);
                      setMyState(false);
                    }}
                  >
                    Speichern
                  </button>
                  <button
                    type='button'
                    className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm'
                    onClick={() => setMyState(false)}
                    ref={cancelButtonRef}
                  >
                    Abbrechen
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
